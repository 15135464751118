import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Remember me, when this all comes to end`}<br parentName="p"></br>{`
`}{`Remember this, when you are left to fend`}<br parentName="p"></br>{`
`}{`The whole world by yourself alone`}<br parentName="p"></br>{`
`}{`And know there is nothing to atone`}</p>
    <p>{`Remember - we were happy here`}<br parentName="p"></br>{`
`}{`Do not, all the sorrow, pain and fear`}<br parentName="p"></br>{`
`}{`Remember me, the best version of self`}<br parentName="p"></br>{`
`}{`Remember us, when we were full of fire and health`}</p>
    <p>{`Remember the time when we did not think of the future or the past`}<br parentName="p"></br>{`
`}{`A time when we believed that all happy things would last`}<br parentName="p"></br>{`
`}{`Forever, oh how wrong we were, how wrong yet still content we were`}<br parentName="p"></br>{`
`}{`I know we can’t go back, but wish we could, believe me darling, that I do`}</p>
    <p>{`Cherish those memories of freedom, laughter, color`}<br parentName="p"></br>{`
`}{`When all we needed was a canvas, paint and each other`}<br parentName="p"></br>{`
`}{`Remember, when our passion fueled our actions`}<br parentName="p"></br>{`
`}{`Remember that we didn’t wish to rest in solitile inaction`}</p>
    <p>{`We lived, fought, breathed and all for simple purpose`}<br parentName="p"></br>{`
`}{`To create something that was truly gorgeous`}<br parentName="p"></br>{`
`}{`We were together but did not forget ourselves`}<br parentName="p"></br>{`
`}{`We each were whole, and never of each other halves`}</p>
    <p>{`Those times were good,yet now they are long gone`}<br parentName="p"></br>{`
`}{`It fell apart: our art, our friendship. And now we are alone`}<br parentName="p"></br>{`
`}{`We cannot bring it back no matter how we try`}<br parentName="p"></br>{`
`}{`The sun has disappeared and left us lone to fend the cloudy sky`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      